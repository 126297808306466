import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

export default function isUserAuthz() {
    const accessToken = Cookies.get('access_token');
    if (accessToken && !isTokenExpired(accessToken)) { // access token is fresh, user can enjoy the app
        return true
    }
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken || isTokenExpired(refreshToken)) { // refresh token is done
        return false
    }
    return true
}

function isTokenExpired(token) {
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
}

