import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import AuthForm from "./auth";
import MainPage from "./main";
import SavedTranslations from "./saved_translations";
import isUserAuthz from "./is_user_auth";
import {useEffect} from "react";
import Cookies from "js-cookie";

export default function App() {
    useEffect(() => {
        const consent = Cookies.get('cookies_accepted');
        if (consent === 'true') {
            console.log("cookies were accepted already");
            console.log("init ga");
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookies_accepted', 'true', {expires: 365});
        console.log("cookies were just accepted");
        console.log("init ga");
    };

    const handleDecline = () => {
        Cookies.set('cookies_accepted', 'false', {expires: 2});
        console.log("client decided to decline");
    };

    return (
        <>
            {/*<CookieConsent*/}
            {/*    enableDeclineButton*/}
            {/*    location="bottom"*/}
            {/*    buttonText="Accept All"*/}
            {/*    cookieName="cookies_accepted"*/}
            {/*    style={{*/}
            {/*        justifyContent: "center",*/}
            {/*        alignItems: "center",*/}
            {/*        background: "white",*/}
            {/*        color: "black",*/}
            {/*        textAlign: "center",*/}
            {/*        borderRadius: "0.375rem",*/}
            {/*        border: "1px solid",*/}
            {/*        margin: "10px auto",*/}
            {/*        width: "100%",*/}
            {/*        boxSizing: "border-box",*/}
            {/*        paddingTop: "0.375rem",*/}
            {/*        paddingBottom: "0.375rem",*/}
            {/*        paddingLeft: "0.375rem",*/}
            {/*        paddingRight: "0.375rem",*/}
            {/*        borderColor: "#e5e7eb",*/}
            {/*    }}*/}
            {/*    declineButtonStyle={{*/}
            {/*        display: "inline-block",*/}
            {/*        borderRadius: "0.375rem",*/}
            {/*        backgroundColor: "white",*/}
            {/*        color: "black",*/}
            {/*        border: "1px solid",*/}
            {/*        paddingTop: "0.375rem",*/}
            {/*        paddingBottom: "0.375rem",*/}
            {/*        paddingLeft: "0.375rem",*/}
            {/*        paddingRight: "0.375rem",*/}
            {/*        borderColor: "#e5e7eb",*/}
            {/*    }}*/}
            {/*    buttonStyle={{*/}
            {/*        display: "inline-block",*/}
            {/*        borderRadius: "0.375rem",*/}
            {/*        backgroundColor: "black",*/}
            {/*        color: "white",*/}
            {/*        paddingTop: "0.375rem",*/}
            {/*        paddingBottom: "0.375rem",*/}
            {/*        paddingLeft: "0.375rem",*/}
            {/*        paddingRight: "0.375rem",*/}
            {/*    }}*/}
            {/*    declineButtonText={"Reject non-essential"}*/}
            {/*    expires={365}*/}
            {/*    onAccept={handleAccept}*/}
            {/*    onDecline={handleDecline}*/}
            {/*>*/}
            {/*    Check our <a href="/privacy-policy" className="text-indigo- hover:underline">*/}
            {/*    Privacy Policy*/}
            {/*</a> and <a href="/terms-of-use" className="text-indigo- hover:underline">*/}
            {/*    Terms of Use*/}
            {/*</a> for details. Click 'Accept all' to let LexiHero use cookies for these purposes. Click*/}
            {/*    'Reject non-essential' to say no to cookies, except those that are strictly necessary.*/}
            {/*</CookieConsent>*/}

            <Router>
                <Routes>
                    <Route path="/auth" element={<AuthForm/>}/>
                    {/*<Route path="/privacy-policy" element={<PrivacyPolicy/>}/>*/}
                    {/*<Route path="/terms-of-use" element={<TermsOfUse/>}/>*/}
                    <Route path="/" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
                    <Route path="/saved-translations" element={<ProtectedRoute><SavedTranslations/></ProtectedRoute>}/>
                </Routes>
            </Router>
        </>
    );
}

function ProtectedRoute({children}) {
    if (!isUserAuthz()) {
        return <Navigate to="/auth" replace/>;
    }
    return children;
}
